<template>
  <div class="header-container">
    <div class="header-left">
      <div class="logo-container">
        <img class="logo" :src="logo">
<!--        <span class="brand">{{$t('siteTitle')}}</span>-->
      </div>
      <div class="tag-container">
        <div class="tag-item" v-for="(item,index) in headerTags" :key="index" @click="clickHandle(item.key)">
          {{ $t(item.type) }}
        </div>
      </div>
    </div>
    <div class="header-right">
      <!--      <div class="openAccount" @click="clickHandle(OPEN_ACCOUNT)">-->
      <!--        <img :src="openAccountIcon">-->
      <!--        {{ $t('header.Open an account') }}-->
      <!--      </div>-->
      <div class="userInfo" v-if="userInfo.nickName" @click="!tourist && clickHandle(USER_INFO)">
        <img class="avatar" :src="userInfo.avatar|| avatar">
        <div class="nickname">{{ userInfo.nickName || $t('header.Nickname') }}</div>
      </div>
      <div class="login-tag" v-if="!userInfo.nickName || tourist">
        <div class="login-tag_item" @click="clickHandle(LOGIN)">{{ $t('header.Sign in') }}</div>
        <div class="cutOffRule"></div>
        <div class="login-tag_item" @click="clickHandle(REGISTER)">{{ $t('header.Sign up') }}</div>
      </div>
      <div class="message" v-if="privateFromUserId" @click="clickHandle(PRIVATE_CHAT)">
        <i class="el-icon-message-solid" style="color: #fff"></i>
      </div>
      <div class="langBtn" @click="changeLang">
        {{ $t('lang') }} <i class="langChange-icon el-icon-sort"></i>
      </div>
    </div>
  </div>
</template>

<script>
import avatar from '@/assets/img/avatar.png';
import openAccountIcon from '@/assets/img/openAnAccount-icon.png';
import logo from '@/assets/img/logo.png';
import {
  LOGIN,
  REGISTER,
  CLASS_SCHEDULE,
  MEMBERSHIP_PRIVILEGES,
  TRANSACTION_PARAMETERS,
  PROFIT_STATISTICS,
  OPEN_ACCOUNT,
  BROADCASTING_ROOM,
  USER_INFO,
  PRIVATE_CHAT, ACTIVITY, ABOUT, CALENDAR
} from '@/constants/sys.js';
import { mapGetters, mapState } from 'vuex';
// import CustomerService from 'utils/customerService';

export default {
  name: 'header-pc',
  data() {
    return {
      logo,
      openAccountIcon,
      USER_INFO,
      OPEN_ACCOUNT,
      LOGIN,
      avatar,
      REGISTER,
      PRIVATE_CHAT,
      headerTags: [
        {
          type: 'header.Curriculum',
          key: CLASS_SCHEDULE,
        },
        {
          type: 'header.Privileges',
          key: MEMBERSHIP_PRIVILEGES,
        },
        {
          type: 'header.Parameter',
          key: TRANSACTION_PARAMETERS,
        },
        {
          type: 'header.Profit',
          key: PROFIT_STATISTICS,
        },
        {
          type: 'header.Activity',
          key: ACTIVITY,
        },
        {
          type: 'header.Calendar',
          key: CALENDAR,
        },
        {
          type: 'header.About',
          key: ABOUT,
        },
        // {
        //   type: 'header.Broadcast',
        //   key: BROADCASTING_ROOM,
        // },
      ],
    };
  },
  computed: {
    ...mapState(['tourist','privateFromUserId']),
    ...mapGetters(['userInfo']),
  },
  methods: {
    clickHandle(type) {
      this.$emit('headerClick', type);
    },
    changeLang() {
      switch (this.$i18n.locale) {
        case 'en':
          this.$i18n.locale = 'zh';
          localStorage.setItem('language', 'zh');
          // new CustomerService().init('zh');
          break;
        case 'zh':
          this.$i18n.locale = 'en';
          localStorage.setItem('language', 'en');
          // new CustomerService().init('en');
          break;
        case 'zh-tw':
          this.$i18n.locale = 'zh-tw';
          localStorage.setItem('language', 'zh-tw');
          // new CustomerService().init('en');
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
.header-container {
  display: flex;
  height: 60px;
  padding: 0 32px;
  background: rgba(25, 25, 25);
  opacity: 1;

  .header-left {
    display: flex;
    flex: 1;

    .logo-container {
      display: flex;
      align-items: center;

      .logo {
        height: 180px;
        width: 180px;
        margin-right: 20px;
      }

      .brand {
        font-size: 34px;
        font-weight: 600;
        color: #D51820;
      }
    }

    .tag-container {
      display: flex;
      //padding-left: 61px;
      align-items: center;

      .tag-item {
        cursor: pointer;
        padding: 18px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
      }

      .tag-item:hover{
        background: #D51820;
      }

    }
  }


  .header-right {
    display: flex;
    align-items: center;
    .message{
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .openAccount {
      display: flex;
      align-items: center;
      height: 40px;
      margin-right: 50px;
      padding: 0 12px;
      background: #D51820;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;

      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }

      //letter-spacing: 30px;
    }

    .userInfo {
      display: flex;
      align-items: center;
      margin-right: 32px;
      cursor: pointer;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 20px;
        margin-right: 12px;
      }

      .nickname {
        max-width: 160px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }

    .login-tag {
      display: flex;
      margin-right: 32px;
      cursor: pointer;

      &_item {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        color: #A7A9AA;
        cursor: pointer;
      }

      .cutOffRule {
        width: 0px;
        height: 20px;
        margin: 0 6px;
        border: 1px solid #A7A9AA;
      }
    }

    .langBtn {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      color: #FFFFFF;
      cursor: pointer;

      .langChange-icon {
        transform: rotate(90deg);
      }
    }
  }
}
</style>

