<template>
  <Popup v-model="popupShow" position="bottom" :closeable="true" :stylePopup="{height:'100%' }"
         @click-overlay="overlayHide" @click-close-icon="overlayHide" :overlay-style="{opacity:0.1}"
         :title-top="$t('header.About')">
    <div class="detailContainer">
      <div v-if="$i18n.locale === 'zh'">
        <div class="content">
          XC600 创始于 2016 年，是一家全球领先金融产品在线交易提供商，为交易者提供外汇、
          贵金属、指数等金融衍生品交易服务。
          XC600 是全球领先的社交网络平台，是全球共享直播社群。用户参与的不仅仅是金融
          投资，更是在交流知识、分享经验、表达情感与思想的平台。
          XC600 将始终以社交协作与投资者教育为基础，始终坚持以客户的需求为我们的核心
          服务原则，努力为客户提供最贴心的服务。
        </div>
        <br>
        <div class="content">
          XC600 中文频道直播：<br> 周一到周五 <br>
          2:30PM--凌晨 0:00（新加波时间）<br>
          6:30AM--4:00PM (GMT）
        </div>
      </div>
      <div v-if="$i18n.locale === 'en'">
        <div class="content">
          Founded in 2016, XC600 is a world-leading online trading provider of financial products，
          providing traders with financial derivatives trading such as forex, precious metals, and indices. XC600 is the world's leading social network platform, a global sharing live community.What
          users participate in is not only financial investment, but also a platform for exchanging knowledge,
          sharing experience, and expressing emotions and ideas. XC600 always adhere to the customers needs as our core service principle, and strive to
          provide customers with the most intimate service.
        </div>
<!--        <div class="content">-->
<!--          XC600 YC Channel (English)：<br> Monday to Friday <br>-->
<!--          2:30PM&#45;&#45;00:00AM(Singapore time)<br>-->
<!--          10:30AM&#45;&#45;17:00PM(GMT)-->
<!--        </div>-->
      </div>
    </div>
  </Popup>
</template>

<script>
// import Popup from '@/components/popup/popup';
import { mapGetters } from 'vuex';
import { getBanners } from '@/api';
import Popup from "@/components/popup/popup.vue";

export default {
  name: 'AboutModel',
  components: {
    Popup
    // Popup,
  },
  data() {
    return {
      popupShow: false,
      banners: [],
      titleTop: '',
    };
  },
  computed: {
    images() {
      return this.banners.map(item => item.imgUrl);
    },
    ...mapGetters(['currentRoomId']),
  },
  methods: {
    overlayShow(type) {
      console.log(type, 'khiugftdr');
      if (type === 'hytq') {
        this.titleTop = 'header.Privileges';
      } else {
        this.titleTop = 'header.Profit';
      }
      this.popupShow = true;
      if (this.currentRoomId) {
        getBanners({ roomId: this.currentRoomId, extraFlag: type })
            .then((res) => {
              this.banners = res.data;
            });
      }
    },
    overlayHide() {
      this.banners = [];
      this.popupShow = false;
    },
    openTab(data) {
      if (data.link) {
        window.open(data.link, '_blank');
      }
    },
  },
};
</script>

<style lang="less" scoped>
.detailContainer {
  padding: 20px;
}
.content{
  text-indent: 2em;
  font-size: 14px;
  line-height: 20px;
}
img {
  width: 100%;
  height: 200px;
}

.title {
  bottom: 0px;
  left: 0;
  right: 0;
  position: absolute;
  height: 40px;
  text-indent: 20px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1111;
}
</style>
